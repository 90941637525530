import axios from "@/config/http.js";

// 工厂评价
export const API = {
  // 工厂评价首页
  async pageIndex(params) {
    return await axios.get("/evaluateFactory/list", { params });
  },
  // 工厂评价导出（导出按钮）
  async factoryExport(id) {
    return await axios.get(`/evaluateFactory/Export/${id}`, {
      responseType: "arraybuffer",
    });
  },
  // 工厂评价归档（归档按钮）
  async factoryFile(factoryEvaluateId) {
    return await axios.put(`/evaluateFactory/factoryFile/${factoryEvaluateId}`);
  },
  // 工厂评价归档（按年份归档）
  async factoryFileYear(year) {
    return await axios.put(`/evaluateFactory/factoryFileYear/${year}`);
  },
  // 工厂评价详情（只做展示的数据）
  async factoryDetail(factoryEvaluateId) {
    return await axios.get(`/evaluateFactory/${factoryEvaluateId}`);
  },
  // 工厂评价选择工厂校验 (选择完工厂之后判断该工厂有没有被评价过)
  async factoryVerify(factoryId) {
    return await axios.get(`/evaluateFactory/Verify/${factoryId}`);
  },
  // 新增工厂评价信息
  async addFactoryEvaluation(params) {
    return await axios.post(`/evaluateFactory`, params);
  },
  // 编辑工厂评价信息
  async editFactoryEvaluation(params) {
    return await axios.put(`/evaluateFactory`, params);
  },
  // 工厂评价可用年份 (归档时候用)
  async factoryEvaluationAnnual() {
    return await axios.get("/evaluateFactory/years");
  },
  // 工厂评价星级
  async factoryEvaluationStar(factoryId) {
    return await axios.get(`/evaluateFactory/level/${factoryId}`);
  },
  // 获取工厂评价树形结构（打分时，拿取给定的树形结构）
  async factoryEvaluationTree() {
    return await axios.get("/evaluateFactory");
  },

  // 工厂信息分页查询
  async factoryPage(params) {
    return await axios.get("/inquiry/factory/page", { params });
  },

  // 工厂评价选择工厂校验
  async checkSelectFactory(id) {
    return await axios.get(`/evaluateFactory/Verify/${id}`);
  },
};
