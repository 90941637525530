<template>
  <div class="jump-address-container">
    <a-modal
      :visible="visibleModal"
      class="modal-style"
      @cancel="cancel"
      :maskClosable="false"
      :width="1490"
    >
      <div>跳转地址</div>
      <a-divider />
      <template slot="footer">
        <a-button @click="cancel">返回</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
      <a-row class="mb2">
        <a-form-model layout="inline" style="display: flex">
          <a-form-model-item label="工厂名称">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.factoryName"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="工厂地址">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.factoryAddress"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="工厂联系人">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.factoryLinkman"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>

          <div class="right" style="line-height: 4rem">
            <a-button type="reload" icon="redo" @click="handleReset"
              >重置</a-button
            >
            <a-button type="primary" icon="search" @click="queryBtn"
              >查询</a-button
            >
          </div>
        </a-form-model>
      </a-row>

      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 600 }"
        :pagination="pagination"
        :rowClassName="setBackground"
        :customRow="
          (record, index) => {
            return {
              on: {
                click: (event) => onRowClick(event, record, index),
              },
            };
          }
        "
      >
        <template slot="factoryName" slot-scope="text, record, index">
          <a-radio
            :checked="preIndex === index"
            @change="handleRadioChange(index)"
          ></a-radio>
          <span :title="text">{{ text }}</span>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "工厂名称",
    ellipsis: true,
    dataIndex: "factoryName",
    scopedSlots: { customRender: "factoryName" },
  },
  {
    title: "工厂地址",
    ellipsis: true,
    dataIndex: "factoryAddress",
  },
  {
    title: "工厂联系人",
    ellipsis: true,
    dataIndex: "factoryLinkman",
  },
  {
    title: "联系电话",
    ellipsis: true,
    dataIndex: "factoryPhonenumber",
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { getColor } from "../../utils/color";
import { filterOpt } from "@/utils/utils";
import { API } from "@/apix/evaluationFactory.js";

export default {
  mixins: [pagination, mixins],
  props: {
    visibleModal: {
      default: false,
      type: Boolean,
    },
    query: null,
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      // treeData: [], // 商品分类
      queryFrom: {
        factoryName: null, // 工厂名称
        factoryAddress: null, // 工厂地址
        factoryLinkman: null, // 工厂联系人
      },
      factoryId: null, // 单击记录表格行的id，（对比id，改变表格行颜色）
      preIndex: null, // 记录上一次是选择的那行商品(索引)
    };
  },
  methods: {
    filterOpt,
    getColor,
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    getSourceData() {
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
      };

      this.loading = true;
      API.factoryPage(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },
    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      this.goodsCategoryId = null;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      this.getSourceData();
    },
    // 处理表格点击事件
    onRowClick(event, record, index) {
      event.stopPropagation(); //阻止默认事件
      this.preIndex = index;
      this.factoryId = record.factoryId;
    },
    // 设置表格行的背景颜色
    setBackground(record) {
      return record.factoryId === this.factoryId ? "tableActive" : "";
    },
    // 选中当前行
    handleRadioChange(index) {
      this.preIndex = index;
    },
    // 处理选中确定
    handleSubmit() {
      let data = this.data[this.preIndex];
      if (typeof data === "undefined") {
        this.$message.error("请选择一个工厂");
      } else {
        if (this.query === "查询") {
          this.$emit("factoryData", data);
          this.cancel();
        } else {
          API.checkSelectFactory(data.factoryId).then((res) => {
            if (!res.data) {
              // 扔到外层
              this.$emit("factoryData", data);
              this.cancel();
            } else {
              this.$message.error("该工厂已有评价记录！");
            }
          });
        }
      }
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.jump-address-container {
}
.ant-input {
  width: 28rem;
}
</style>
