<template>
  <div class="radar-modal-container">
    <a-modal
      :visible="radarModal"
      class="modal-style"
      @cancel="cancel"
      :maskClosable="false"
      :width="650"
    >
      <template slot="footer">
        <a-button
          @click="
            () => {
              cancel();
            }
          "
          >返回</a-button
        >
      </template>
      <template slot="title">
        <div>
          <span>{{ config.record.factoryName }}</span> |
          <span>{{ config.record.evaluateTime }}</span>
        </div>
      </template>
      <div id="radar"></div>
    </a-modal>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins.js";
import * as echarts from "echarts/core";
import { API } from "@/apix/evaluationFactory.js";
export default {
  name: "radar",
  mixins: [mixins],
  props: {
    radarModal: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      indicator: [
        // { name: "易用性", max: "100" },
        // { name: "功能", max: "100" },
        // { name: "拍照", max: "100" },
        // { name: "跑分", max: "100" },
        // { name: "续航", max: "100" },
      ],
      scoreList: [
        // {
        //   name: "华为手机",
        //   value: [80, 90, 80, 82, 90],
        // },
      ],
    };
  },
  mounted() {},
  methods: {
    // handleOk() {},
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.$nextTick(() => {
        let myChart = echarts.init(document.getElementById("radar"));

        let option = {
          //配置维度的最大值
          radar: {
            name: {
              show: true,
              color: "red",
            },
            //   雷达图的指示器，用来指定雷达图中的多个变量（维度）
            indicator: this.indicator,
            // shape: "circle", //对雷达图形设置成一个圆形,可选 circle:圆形,polygon:多角形(默认)
          },
          tooltip: {
            trigger: "axis",
          },
          series: [
            {
              type: "radar",
              label: {
                // show: true, //显示数值
              },
              // tooltip: {
              //   trigger: "item",
              // },
              areaStyle: {}, //每个雷达图形成一个阴影的面积
              data: this.scoreList,
            },
          ],
        };
        // 绘制图表
        myChart.setOption(option);
      });
    },
    // 获取雷达图信息数据
    factoryEvaluationStar(id) {
      API.factoryEvaluationStar(id).then((res) => {
        if (res.success) {
          this.indicator = res.data.indicator;
          this.scoreList = res.data.dataList;
          this.drawLine();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
  },
  created() {
    this.factoryEvaluationStar(this.config.id);
  },
};
</script>

<style lang="scss" scoped>
#radar {
  width: 600px;
  height: 400px;
}
</style>
