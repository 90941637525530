<template>
  <div class="evalution-factory-container">
    <div class="top-container">
      <a-row>
        <a-form-model layout="inline" style="display: flex">
          <a-form-model-item label="年度">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.annual"
              @keyup.enter="queryBtn"
              style="width: 100%"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="评价日期">
            <a-range-picker
              format="YYYY-MM-DD"
              @change="handleDate"
              v-model="DateTime"
              style="width: 100%"
            />
          </a-form-model-item>
          <a-form-model-item label="工厂">
            <a-input
              placeholder="请选择"
              v-model="factoryName"
              style="width: 100%"
            >
              <a-icon slot="suffix" type="dash" @click="openModal('工厂')" />
            </a-input>
          </a-form-model-item>
          <div class="right">
            <a-button type="reload" icon="redo" @click="handleReset"
              >重置</a-button
            >
            <a-button type="primary" icon="search" @click="queryBtn"
              >查询</a-button
            >
          </div>
        </a-form-model>
      </a-row>
    </div>

    <div class="bottom-container">
      <div v-if="returnPage">
        <a-button class="mb2 mr2" type="primary" @click="openModal('新增')"
          >评价</a-button
        >
        <a-button class="mb2 mr2" @click="openModal('批量归档')"
          >批量归档</a-button
        >
        <a-button class="mb2" @click="handleFileQuery">归档查询</a-button>
      </div>
      <a-button v-else class="mb2" @click="handleReturn">返回</a-button>
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 410 }"
        :pagination="pagination"
      >
        <template slot="levelStar" slot-scope="text, record">
          <span @click="openModal('星级', record.id, record)">
            <a-rate v-model="record.levelStar" disabled />
          </span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <span
            class="hover"
            v-if="!returnPage"
            :style="{ color: `${getColor('主题')}` }"
            @click="openModal('详情', record.id)"
            >查看</span
          >
          <span
            class="hover"
            v-if="returnPage"
            :style="{ color: `${getColor('主题')}` }"
            @click="openModal('评价', record.id)"
            >评价</span
          >
          <span
            class="hover"
            v-if="returnPage"
            :style="{ color: `${getColor('主题')}` }"
            @click="handleSingleFile(record.id, record)"
            >归档</span
          >
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            @click="handleImport(record.id)"
            >导出</span
          >
        </template>
      </a-table>
    </div>
    <EvSystemModal
      v-if="evSystemModal"
      :evSystemModal="evSystemModal"
      :config="configFrom"
    />
    <EvSystemModalDetail
      v-if="evSystemModalDetail"
      :evSystemModalDetail="evSystemModalDetail"
      :config="configFrom"
    />
    <RadarModal
      v-if="radarModal"
      :radarModal="radarModal"
      :config="configFrom"
    />
    <JumpAddress
      v-if="visibleModal"
      :visibleModal="visibleModal"
      @factoryData="handleFactory"
      :query="'查询'"
    />
    <AnnualModal :annualModal="annualModal" v-if="annualModal" />
  </div>
</template>

<script>
const columns = [
  {
    title: "年度",
    ellipsis: true,
    dataIndex: "annual",
  },
  {
    title: "工厂名称",
    ellipsis: true,
    dataIndex: "factoryName",
  },
  {
    title: "评价日期",
    ellipsis: true,
    dataIndex: "evaluateTime",
  },
  {
    title: "得分",
    ellipsis: true,
    dataIndex: "score",
  },
  {
    title: "星级",
    ellipsis: true,
    dataIndex: "levelStar",
    scopedSlots: { customRender: "levelStar" },
  },
  {
    title: "操作",
    ellipsis: true,
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { getColor } from "@/utils/color";
import EvSystemModal from "./evSystemModal.vue";
import EvSystemModalDetail from "./evSystemModalDetail.vue";
import RadarModal from "./radarModal.vue";
import JumpAddress from "./jumpAddress.vue";
import AnnualModal from "./annualModal.vue";
import { API } from "@/apix/evaluationFactory.js";
import { tips } from "../../utils/tips";
import moment from "moment";
export default {
  mixins: [pagination, mixins],
  components: {
    EvSystemModal,
    EvSystemModalDetail,
    RadarModal,
    JumpAddress,
    AnnualModal,
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      queryFrom: {
        annual: null, // 年度
        startTime: null, // 开始时间
        endTime: null, // 开始时间
        factoryId: null, // 工厂id
        state: 0, // 归档状态
      },
      returnPage: true, // 返回页面的显示切换
      factoryName: null, // 工厂名称
      DateTime: null, // 日期组件选择完之后的日期对象
      evSystemModal: false, // 评价体系弹出框
      evSystemModalDetail: false,
      radarModal: false, // 雷达图
      visibleModal: false, // 工厂选择框
      annualModal: false, // 归档时年份选择框
      configFrom: {
        id: null,
        type: null,
        record: null,
      },
    };
  },
  methods: {
    getColor,
    // 处理归档页面单独显示逻辑(查询已归档数据)
    handleFileQuery() {
      this.returnPage = false;
      this.queryFrom.state = 1;
      this.pagination.current = 1;
      this.getSourceData();
    },
    openModal(type, id, record) {
      if (type === "新增") {
        this.evSystemModal = true;
        this.configFrom.type = type;
      } else if (type === "评价") {
        this.evSystemModal = true;
        this.configFrom.id = id;
        this.configFrom.type = type;
      } else if (type === "详情") {
        this.evSystemModalDetail = true;
        this.configFrom.id = id;
        this.configFrom.type = type;
      } else if (type === "星级") {
        this.radarModal = true;
        this.configFrom.id = id;
        this.configFrom.record = record;
      } else if (type === "工厂") {
        this.visibleModal = true;
      } else if (type === "批量归档") {
        this.annualModal = true;
      }
    },
    closeModal() {
      this.evSystemModal = false;
      this.evSystemModalDetail = false;
      this.radarModal = false;
      this.visibleModal = false;
      this.annualModal = false;
    },
    // 处理归档返回逻辑
    handleReturn() {
      this.returnPage = true;
      this.queryFrom.state = 0;
      this.getSourceData();
    },
    // 处理日期
    handleDate(date) {
      if (date.length === 0) {
        this.queryFrom.startTime = null;
        this.queryFrom.endTime = null;
      } else {
        this.queryFrom.startTime = moment(date[0]).format("YYYY-MM-DD");
        this.queryFrom.endTime = moment(date[1]).format("YYYY-MM-DD");
      }
    },
    getSourceData() {
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
      };
      this.loading = true;
      API.pageIndex(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.levelStar = Number(i.levelStar);
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },
    // 工厂返回数据
    handleFactory(val) {
      this.factoryName = val.factoryName;
      this.queryFrom.factoryId = val.factoryId;
    },
    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      this.factoryName = null;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      if (!this.returnPage) {
        this.queryFrom.state = 1;
      } else {
        this.queryFrom.state = 0;
      }

      this.queryFrom.startTime = null;
      this.queryFrom.endTime = null;
      this.DateTime = null;
      this.getSourceData();
    },

    // 单条数据归档
    handleSingleFile(id, record) {
      if (this.data.length < 2) {
        this.pagination.current--;
      }
      let self = this;
      this.$confirm({
        title: `是否确认归档 "${record.annual}年度 ${record.factoryName}" 工厂评价`,
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.factoryFile(id).then((res) => {
            tips(res, "归档");
            if (res.success) {
              self.getSourceData();
            }
          });
        },
      });
    },
    // 数据导出
    handleImport(id) {
      API.factoryExport(id).then((res) => {
        const link = document.createElement("a");
        //对文件名乱码转义--【Node.js】使用iconv-lite解决中文乱码
        let blob = new Blob([res.data], {
          type: "application/pdf;chartset=UTF-8",
        });
        let s = res.headers["content-disposition"];
        let x = s.slice(s.indexOf("=") + 1);
        let fileName = decodeURI(decodeURIComponent(escape(x)), "UTF-8");
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.exportLoading = false;
      });
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.top-container {
  height: 9rem;
}
.bottom-container {
  min-height: 70%;
}
.hover {
  margin-right: 2rem;
}
.ant-input {
  width: 28.5rem;
}
.mr2 {
  margin-right: 2rem;
}
</style>
