<template>
  <div class="ev-system-modal-container">
    <a-modal
      :visible="evSystemModal"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      :maskClosable="false"
      title="评价"
      :width="1200"
    >
      <div class="mb2" style="display: flex">
        <span class="star factory">工厂：</span>
        <a-input
          placeholder="请选择"
          v-model="submitFrom.factoryName"
          :disabled="config.type === '评价'"
        >
          <a-icon slot="suffix" type="dash" @click="openModal" />
        </a-input>
      </div>

      <div class="evaluationtitle">工厂评价体系</div>
      <a-table
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :key="data.key"
        :scroll="{ y: 800 }"
        :pagination="false"
      >
        <template slot="inspectionStandard" slot-scope="text">
          <div style="white-space: pre-line">{{ text }}</div>
        </template>
        <template slot="score" slot-scope="text, record">
          <a-select
            show-search
            placeholder="请选择"
            :not-found-content="null"
            style="width: 8.8rem"
            v-if="record.selection && record.normType === 1"
            v-model="record.score"
            @change="handleTotal"
          >
            <a-select-option
              v-for="(item, i) in selectionStr(record.selection)"
              :value="item"
              :key="i"
            >
              {{ item }}
            </a-select-option>
          </a-select>
          <a-input-number
            placeholder="请输入"
            v-if="!record.selection && record.normType === 1"
            :min="0"
            :max="record.normFullmark"
            :precision="1"
            v-model="record.score"
            @change="handleTotal"
          ></a-input-number>
          <span v-if="!record.selection && record.normType === 0">
            {{ text }}
          </span>
        </template>
      </a-table>

      <div class="mt2">
        <a-form-model layout="inline">
          <a-form-model-item label="上传附件：">
            <UploadFile v-model="submitFrom.evaluateFile" />
            <span style="color: rgba(51, 51, 51, 0.65)"
              >单个文件不能超过10M
            </span>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 跳转页面 -->
    <JumpAddress
      :visibleModal="visibleModal"
      v-if="visibleModal"
      @factoryData="handleReceiveData"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "评价内容",
    ellipsis: true,
    dataIndex: "label",
    width: "30%",
  },
  {
    title: "分数",
    ellipsis: true,
    dataIndex: "normFullmark",
    width: "10%",
  },
  {
    title: "校验标准",
    ellipsis: true,
    dataIndex: "inspectionStandard",
    width: "40%",
    scopedSlots: { customRender: "inspectionStandard" },
  },
  {
    title: "打分",
    ellipsis: true,
    dataIndex: "score",
    scopedSlots: { customRender: "score" },
    width: "20%",
  },
];
import mixins from "@/mixins/mixins.js";
import UploadFile from "@/components/uploadFile";
import JumpAddress from "./jumpAddress.vue";
import { API } from "@/apix/evaluationFactory.js";
import { tips } from "../../utils/tips";
import { updateTreeDataAdd } from "@/utils/utils.js";
export default {
  mixins: [mixins],
  props: {
    evSystemModal: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    UploadFile,
    JumpAddress,
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      submitFrom: {
        evaluateDentail: [], // 树形结构
        evaluateFile: [], // 工厂评价附件
        factoryId: null,
        factoryName: null,
      },
      visibleModal: false, // 工厂弹框显示
    };
  },
  methods: {
    handleOk() {
      if (this.config.type === "新增") {
        this.submitEvaluation();
      } else if (this.config.type === "评价") {
        this.editEvaluation();
      }
    },
    openModal() {
      this.visibleModal = true;
    },
    closeModal() {
      this.visibleModal = false;
    },
    // 分割后端返回来selection,充当打分下拉框的选择项
    selectionStr(str) {
      return str.split(",");
    },
    // 处理跳转地址选中传回来的数据
    handleReceiveData(data) {
      this.submitFrom.factoryId = data.factoryId;
      this.submitFrom.factoryName = data.factoryName;
    },
    // 拿取打分工厂评价树形结构数据
    factoryEvaluationTree() {
      API.factoryEvaluationTree().then((res) => {
        if (res.success) {
          this.data = res.data;
          this.getNewData(this.data);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    getNewData(arr) {
      arr.map((item) => {
        item.key = item.id;
        if (item.children) {
          this.getNewData(item.children);
        }
        return arr;
      });
    },
    // 提交打分情况
    submitEvaluation() {
      if (this.submitFrom.factoryId === null) {
        this.$message.error("请选择一个工厂");
      } else {
        this.submitFrom.evaluateDentail = this.data;
        API.addFactoryEvaluation(this.submitFrom).then((res) => {
          tips(res, "新增评价");
          if (res.success) {
            this.$parent.getSourceData();
            this.cancel();
          }
        });
      }
    },
    // 编辑打分情况
    editEvaluation() {
      if (this.submitFrom.factoryId === null) {
        this.$message.error("请选择一个工厂");
      } else {
        this.submitFrom.evaluateDentail = this.data;
        this.submitFrom.id = this.config.id;
        API.editFactoryEvaluation(this.submitFrom).then((res) => {
          tips(res, "编辑评价");
          if (res.success) {
            this.$parent.getSourceData();
            this.cancel();
          }
        });
      }
    },
    // 工厂评价详情
    getFactoryDetail(id) {
      API.factoryDetail(id).then((res) => {
        this.getNewData(res.data.evaluateDentail);
        if (res.success) {
          this.data = res.data.evaluateDentail;
          this.submitFrom.evaluateFile = res.data.evaluateFile;
          this.submitFrom.factoryName = res.data.factoryName;
          this.submitFrom.factoryId = res.data.factoryId;
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },

    // 打完分重新计算总分
    handleTotal() {
      let result = updateTreeDataAdd(this.data, "score");
      // this.data = topTreeNode(result, "score");
      this.data = result;
      this.getNewData(this.data);
    },
  },
  created() {
    if (this.config.type === "新增") {
      this.factoryEvaluationTree();
    } else if (this.config.type === "评价") {
      this.getFactoryDetail(this.config.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.evaluationtitle {
  text-align: center;
  border: 1px solid #eee;
  background: #fafafa;
  padding: 1.5rem 0;
  font-weight: 700;
  font-size: 1.8rem;
}
.fs {
  font-size: 1.7rem;
  margin-right: 1rem;
}
.fs:hover {
  color: #ff4600;
  cursor: pointer;
}
.mb2 {
  margin-bottom: 2rem;
  width: 28.5rem;
}
.mt2 {
  margin-top: 2rem;
}
.factory {
  width: 6rem;
  line-height: 3.5rem;
}
/deep/ .ant-table-tbody:nth-child(1) {
  background: #fafafa;
}
</style>
