<template>
  <div class="annual-modal-container">
    <a-modal
      :visible="annualModal"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      :maskClosable="false"
      title="年份"
      :width="533"
    >
      <a-form-model layout="inline">
        <a-form-model-item label="年份">
          <a-select
            show-search
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
            :default-active-first-option="false"
            :style="{ width: '28.5rem' }"
            :not-found-content="null"
            v-model="annual"
          >
            <a-select-option
              v-for="(item, i) in annualList"
              :value="item"
              :key="i"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins.js";
import { API } from "@/apix/evaluationFactory.js";
import { tips } from "../../utils/tips";
export default {
  mixins: [mixins],
  props: {
    annualModal: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      annual: null, // 年份
      annualList: [], // 年份可选项（后端传来可归档的年份）
    };
  },
  methods: {
    handleOk() {
      this.factoryFileYear(this.annual);
    },
    // 拿取可选择年份
    getSelectYear() {
      API.factoryEvaluationAnnual().then((res) => {
        this.annualList = res.data;
      });
    },

    // 归档
    factoryFileYear(year) {
      API.factoryFileYear(year).then((res) => {
        tips(res, "归档");
        if (res.success) {
          this.cancel();
          this.$parent.getSourceData();
        }
      });
    },
  },
  created() {
    this.getSelectYear();
  },
};
</script>

<style lang="scss" scoped>
.ant-input {
  width: 28.5rem;
}
</style>
