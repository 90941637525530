<template>
  <div class="ev-system-modal-container">
    <a-modal
      :visible="evSystemModalDetail"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      :maskClosable="false"
      title="评价"
      :width="1200"
    >
      <div class="mb2">
        <a-input
          placeholder="请选择"
          v-model="submitFrom.factoryName"
          disabled
        />
      </div>

      <div class="evaluationtitle">工厂评价体系</div>
      <a-table
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :key="data.key"
        :scroll="{ y: 800 }"
        :pagination="false"
      >
        <template slot="inspectionStandard" slot-scope="text">
          <div style="white-space: pre-line">{{ text }}</div>
        </template>
        <template slot="score" slot-scope="text">
          <span>
            {{ text }}
          </span>
        </template>
      </a-table>

      <div class="mt2">
        <a-form-model layout="inline">
          <a-form-model-item label="上传附件：">
            <div
              class="linkColor"
              v-for="(item, i) in submitFrom.evaluateFile"
              :key="i"
            >
              <a :href="item" :download="item">{{ item }}</a>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>

      <!-- 跳转页面 -->
      <JumpAddress
        :visibleModal="visibleModal"
        v-if="visibleModal"
        @goodsData="handleReceiveData"
      />
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "评价内容",
    ellipsis: true,
    dataIndex: "label",
    width: "30%",
  },
  {
    title: "分数",
    ellipsis: true,
    dataIndex: "normFullmark",
    width: "10%",
  },
  {
    title: "校验标准",
    ellipsis: true,
    dataIndex: "inspectionStandard",
    width: "40%",
    scopedSlots: { customRender: "inspectionStandard" },
  },
  {
    title: "打分",
    ellipsis: true,
    dataIndex: "score",
    scopedSlots: { customRender: "score" },
    width: "20%",
  },
];
import mixins from "@/mixins/mixins.js";
// import UploadFile from "@/components/uploadFile";
import JumpAddress from "./jumpAddress.vue";
import { API } from "@/apix/evaluationFactory.js";
export default {
  mixins: [mixins],
  props: {
    evSystemModalDetail: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    // UploadFile,
    JumpAddress,
  },
  data() {
    return {
      columns,
      data: [
        // {
        //   id: 1,
        //   parentId: null,
        //   label: "2022",
        //   normFullmark: 100,
        //   parentName: null,
        //   markType: null,
        //   normType: 0,
        //   evaluateId: 1,
        //   inspectionStandard: "",
        //   selection: null, // 选择项
        //   visibleIcon: 1, // 前端需要字段(用于判断图片显示情况)
        //   score: "0",
        // },
        // {
        //   id: 2,
        //   parentId: null,
        //   label: "基础",
        //   normFullmark: 50,
        //   parentName: null,
        //   markType: null,
        //   normType: 0,
        //   evaluateId: 1,
        //   inspectionStandard: "校验标准2",
        //   selection: null, // 选择项
        //   score: "0",
        //   children: [
        //     {
        //       id: 4,
        //       parentId: 2,
        //       label: "注册资本",
        //       normFullmark: 20,
        //       parentName: "基础",
        //       markType: 1,
        //       normType: 1,
        //       evaluateId: 1,
        //       inspectionStandard: "校验标准1dfsdf\nfgsdfgdfgd\n高峰时段风格gdf",
        //       selection: null, // 选择项
        //       score: 1.5,
        //     },
        //     {
        //       id: 5,
        //       parentId: 2,
        //       label: "注册名字",
        //       normFullmark: 30,
        //       parentName: "基础",
        //       markType: 1,
        //       normType: 1,
        //       evaluateId: 1,
        //       inspectionStandard: "校验标准4",
        //       selection: null, // 选择项
        //       score: 2,
        //     },
        //   ],
        // },
        // {
        //   id: 3,
        //   parentId: null,
        //   label: "资质",
        //   normFullmark: 50,
        //   parentName: null,
        //   markType: 1,
        //   normType: 1,
        //   evaluateId: 1,
        //   inspectionStandard: "校验标准5",
        //   selection: null, // 选择项
        //   score: 6,
        // },
      ],
      loading: false,
      submitFrom: {
        evaluateDentail: [], // 树形结构
        evaluateFile: [], // 工厂评价附件
        factoryId: null,
        factoryName: null,
      },
      visibleModal: false, // 弹框显示
    };
  },
  methods: {
    handleOk() {},
    closeModal() {
      this.draftModal = false;
    },
    // 处理新增下级节点
    handleSubLevel() {
      this.draftModal = true;
    },
    // 处理新增同级节点
    handleSameLevel() {
      this.draftModal = true;
    },
    // 分割后端返回来selection,充当打分下拉框的选择项
    selectionStr(str) {
      return str.split(",");
    },
    // 处理跳转地址选中传回来的数据
    handleReceiveData(data) {
      this.submitFrom.factoryId = data.goodsId;
      this.submitFrom.factoryName = data.goodsName;
    },
    // 工厂评价详情
    getFactoryDetail(id) {
      API.factoryDetail(id).then((res) => {
        if (res.success) {
          this.data = res.data.evaluateDentail;
          this.submitFrom.evaluateFile = res.data.evaluateFile;
          this.submitFrom.factoryName = res.data.factoryName;
          this.getNewData(this.data);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    getNewData(arr) {
      arr.map((item) => {
        item.key = item.id;
        if (item.children) {
          this.getNewData(item.children);
        }
        return arr;
      });
    },
  },
  created() {
    this.getFactoryDetail(this.config.id);
  },
};
</script>

<style lang="scss" scoped>
.evaluationtitle {
  text-align: center;
  border: 1px solid #eee;
  background: #fafafa;
  padding: 1.5rem 0;
  font-weight: 700;
  font-size: 1.8rem;
}
.fs {
  font-size: 1.7rem;
  margin-right: 1rem;
}
.fs:hover {
  color: #ff4600;
  cursor: pointer;
}
.mb2 {
  margin-bottom: 2rem;
  width: 28.5rem;
}
.mt2 {
  margin-top: 2rem;
}
.linkColor {
  color: #ff4600;
}
/deep/ .ant-table-tbody:nth-child(1) {
  background: #fafafa;
}
</style>
